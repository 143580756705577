<template>
  <div  v-if="
      this.result.valueLine.country != '' &&
      this.result.valueLine.country != '0'
    ">

    <defaultFieldTypes
      :fieldAttributes="{
        ...fieldAttributes,
        associatedOption: associatedOption,
      }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
    :value="value"></defaultFieldTypes>
  </div>
</template>

<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import requests from '@/js/requests';
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result","value"],
  data() {
    return { associatedOption: [] };
  },
  created() {
    let value = this.result.valueLine.country;
    this.getOptions(value);
  },
  watch: {
    "result.valueLine.country": function (value) {
      console.log("country changed", value);
      this.getOptions(value);
    },
  },
  methods: {
    getOptions(value) {
      if (value != "") {
        let options = {
          function: "getRatAllOperator",
          ratCountryval: value,
        };
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=configureAndRunTests',
          data: options,
        })
            .then(function (response) {
            // //console.log(response);
            caller.associatedOption = response.data.result.json.operatorList;
          })
          .catch(function () {
            //handle error
          });
      }
    },
  },
};
</script>